// email: string
import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

export interface authFormFieldStore {
  email: string;
  updateEmail: (newEmail: string) => void;
  password: string;
  updatePassword: (newPassword: string) => void;
  companyName: string;
  updateCompanyName: (newCompanyName: string) => void;
  name: string;
  updateName: (newName: string) => void;
}

// Manages getting/setting field values in the auth form so that they can persist as the user switches between form views or closes and re-opens an auth modal
const useAuthFormFieldStore = create<authFormFieldStore>((set, get) => ({
  email: '',
  password: '',
  companyName: '',
  name: '',
  updateEmail: (newEmail: string) => set({ email: newEmail }),
  updatePassword: (newPassword: string) => set({ password: newPassword }),
  updateCompanyName: (newCompanyName: string) => set({ companyName: newCompanyName }),
  updateName: (newName: string) => set({ name: newName }),
}));

export const useEmailField = () =>
  useAuthFormFieldStore(
    useShallow((store): [string, authFormFieldStore['updateEmail']] => [
      store.email,
      store.updateEmail,
    ]),
  );

export const usePasswordField = () =>
  useAuthFormFieldStore(
    useShallow((store): [string, authFormFieldStore['updatePassword']] => [
      store.password,
      store.updatePassword,
    ]),
  );

export const useCompanyNameField = () =>
  useAuthFormFieldStore(
    useShallow((store): [string, authFormFieldStore['updateCompanyName']] => [
      store.companyName,
      store.updateCompanyName,
    ]),
  );

export const useNameField = () =>
  useAuthFormFieldStore(
    useShallow((store): [string, authFormFieldStore['updateName']] => [
      store.name,
      store.updateName,
    ]),
  );
